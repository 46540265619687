import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'
// import BootstrapVue3 from 'bootstrap-vue-3'
import axios from 'axios'
import Vue3EasyDataTable from 'vue3-easy-data-table'

import vSelect from 'vue-select'

import 'vue3-easy-data-table/dist/style.css';

import "../src/assets/style/main.scss"

import 'vue-select/dist/vue-select.css';

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const store = createStore({
    state:{
      markerMap:'',
      centerMap:''
    },
    mutations: {
      getmarkerMap (state, n) {
        axios.get(`http://localhost:8000/api/get-maps/`+n).then(res=>{
          let data = res.data
          let arrMarker = []
          for(let i=0; i<data.length; i++){
            let json = [
              data[i].latitude,data[i].longitude
            ]
            arrMarker[i] = json
          }
          state.markerMap = arrMarker
          state.centerMap = arrMarker[0]
        })
      },
      setinitMarker(state,n){
        state.markerMap = n
        state.centerMap = n[0]
      }
    }
})

const app = createApp(App)
app.use(router)
app.use(store)
app.component('EasyDataTable', Vue3EasyDataTable)
app.component('v-select', vSelect)
// app.use(BootstrapVue3)
app.mount('#app')

