<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    
  },
  data(){
    return{
      baseApi: 'http://localhost:8000',
      isLogin: false
    }
  },
  mounted(){

  }
}
</script>

