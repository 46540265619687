/* eslint-disable */

import { createRouter, createWebHistory } from 'vue-router'

//define a routes
const routes = [
    {
        path: '/',
        redirect: '/admin/dashboard'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        meta:{title:'Login',page:"login",menu:'login'}
    },
    {
        path: '/admin/dashboard',
        name: 'dashboard',
        beforeEnter : loginFirst,
        component: () => import('./pages/Dashboard.vue'),
        meta:{title:'Dashboard',page:"dashboard",menu:'dashboard'}
    }
]

//auth login
function loginFirst(to, from, next)
{
var isAuthenticated= false;
if(localStorage.getItem('isLogin'))
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
      next('/login');
 }
}

//create router
const router = createRouter({
    history: createWebHistory(),
    routes
})

// router.beforeEach((to) => {
//     const titleFromParams = to.params?.pageTitle
  
//     if (titleFromParams) {
//       document.title = `${titleFromParams} - ${document.title}`
//     } else {
//       document.title = to.meta?.pageTitle ?? 'Default Title'
//     }
// })

export default router